<template lang="pug">
div
  form(@submit.prevent="onSubmit" v-if="!loading")
    .profile(v-if="profile")
      app-form-input(type="text" :label="$t('profile.username')" :value="profile.id_portal" disabled)
      hr.divider
      app-checkbox(label="Editar" v-model="active")
      app-form-input(type="text" :label="$t('profile.name')" :value="profile.name" :disabled="!active" name="name")
      app-form-input(type="text" :label="$t('profile.email')" :value="profile.email" :disabled="!active" name="email")
      app-form-input(type="text" :label="$t('profile.phone')" :value="profile.phone" :disabled="!active" name="phone")
      app-form-input(type="text" label="RFC" :value="profile.vat || ''" :disabled="!active" name="vat" pattern="^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$")
      app-form-input(type="text" :label="$t('profile.business_name')" :value="profile.razon_social" :disabled="!active" name="razon_social")
      AppFormInput(type="select" v-model:value="regimen_id" label="Regimen Fiscal" :options="list_regimen_fiscal" :disabled="!active" name="l10n_mx_edi_fiscal_regime")
      hr.divider
      AppFormInput(type="select" v-model:value="estado_id" label="States" :options="estados" @change="changeState" :disabled="!active" name="state_id")
      AppFormInput(type="select" v-model:value="locality_id" label="Locality" :options="localities" :disabled="!active" name="l10n_mx_edi_locality_id")
      AppFormInput(type="select" v-model:value="city_id" label="City" :options="cities" :disabled="!active" name="city_id")
      app-form-input(type="text" :label="$t('profile.street')" :value="profile.street_name" :disabled="!active" name="street_name")
      app-form-input(type="text" :label="$t('profile.street_number')" :value="profile.street_number.toString()" :disabled="!active" name="street_number")
      app-form-input(type="text" :label="$t('profile.street_number2')" :value="profile.street_number2.toString()" :disabled="!active" name="street_number2")
      app-form-input(type="text" :label="$t('profile.colony')" :value="profile.l10n_mx_edi_colony.toString()" :disabled="!active" name="l10n_mx_edi_colony")
      app-form-input(type="text" :label="$t('profile.reference')" :value="profile.referencia_ubicacion_servicio.toString()" :disabled="!active" name="referencia_ubicacion_servicio")
      AppButton(label="Guardar" :disabled="true")
</template>

<script>
import { ref, onMounted, watch } from "vue";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
import { axios } from "@/utils/axios";
import { store } from "@/store";

export default {
  components: { AppFormInput, AppButton, AppCheckbox },
  setup() {
    const profile = ref({});
    const active = ref(false);
    const estados = ref([]);
    const estado_id = ref("506");
    const localities = ref([]);
    const locality_id = ref("525");
    const cities = ref([]);
    const city_id = ref("1808");
    const list_regimen_fiscal = ref([]);
    const regimen_id = ref("616");
    const loading = ref(true);
    //

    const getStates = async () => {
      const { data } = await axios.get("location/country/156/state");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      estados.value = arrayData;
      // console.log(estados.value);
    };

    const getLocality = async (id) => {
      const { data } = await axios.get("location/state/" + id + "/locality");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      localities.value = arrayData;
      // console.log(localities.value);
    };

    const getCities = async (id) => {
      const { data } = await axios.get("location/state/" + id + "/city");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      cities.value = arrayData;
    };

    const getListRegimenFiscal = async () => {
      const { data } = await axios.get("contact/regimenfiscales");
      list_regimen_fiscal.value = data.payload;
      // console.log(list_regimen_fiscal.value);
    };

    const changeState = async () => {
      console.log("changeState");
      await getLocality(estado_id.value);
      await getCities(estado_id.value);
    };

    const getProfile = async () => {
      const { data } = await axios.get("contact/");
      profile.value = data.payload[0];
      // console.log(profile.value);
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        await getProfile();
        // const { data } = await axios.get("contact/");
        // profile.value = data.payload[0];
        estado_id.value = profile.value.state_id[0].toString();
        locality_id.value = profile.value.l10n_mx_edi_locality_id[0].toString();
        city_id.value = profile.value.city_id[0].toString();
        regimen_id.value = profile.value.l10n_mx_edi_fiscal_regime.toString();
        await getStates();
        await getLocality(estado_id.value);
        await getCities(estado_id.value);
        await getListRegimenFiscal();
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
        loading.value = false;
      }
    });

    watch(profile, async (current) => {
      regimen_id.value = current.l10n_mx_edi_fiscal_regime.toString();
      estado_id.value = current.state_id[0].toString();
      locality_id.value = current.l10n_mx_edi_locality_id[0].toString();
      city_id.value = current.city_id[0].toString();
      regimen_id.value = current.l10n_mx_edi_fiscal_regime.toString();
    });

    const onSubmit = async (e) => {
      console.log("Submit...");
      active.value = false;
      const formData = {};
      const body = new FormData(e.target);
      body.forEach((value, key) => (formData[key] = value));

      let fields = {
        id: parseInt(profile.value.id),
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        vat: formData.vat,
        razon_social: formData.razon_social,
        l10n_mx_edi_fiscal_regime: formData.l10n_mx_edi_fiscal_regime,
        state_id: parseInt(formData.state_id),
        l10n_mx_edi_locality_id: parseInt(formData.l10n_mx_edi_locality_id),
        city_id: parseInt(formData.city_id),
        street_name: formData.street_name,
        street_number: formData.street_number,
        street_number2: formData.street_number2,
        l10n_mx_edi_colony: formData.l10n_mx_edi_colony,
        referencia_ubicacion_servicio: formData.referencia_ubicacion_servicio,
      };

      const profile_id = profile.value.id.toString();
      const { data } = await axios.post("lead/contact/" + profile_id, fields);

      console.log(data);

      await getProfile();
      // alert("Se han actualizado los datos correctamente");
    };

    return {
      profile,
      active,
      onSubmit,
      getStates,
      estados,
      estado_id,
      changeState,
      getLocality,
      localities,
      locality_id,
      getCities,
      cities,
      city_id,
      getListRegimenFiscal,
      list_regimen_fiscal,
      regimen_id,
      loading,
    };
  },
};
</script>
<style scoped>
.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
